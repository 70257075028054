import React from 'react'
import _ from 'lodash'

import RaccAlertMessage from 'Shared/racc_alert_message'

type noticeMessagesProps = {
  messages: string | string[]
}

const NoticeMessages = ({ messages }: noticeMessagesProps): JSX.Element => {
  const renderMessages =
    typeof messages === 'object'
      ? _.map(
          messages,
          (msg: string): JSX.Element => (
            <div key={msg} data-testid='notice_messages'>
              {msg}
            </div>
          )
        )
      : messages && <div data-testid='notice_messages'>{messages}</div>

  return <RaccAlertMessage type='notice'>{renderMessages}</RaccAlertMessage>
}

export default NoticeMessages
