import React, { Dispatch, ReactElement, SetStateAction, useState } from 'react'

import SuccessMessage from 'Shared/success_message'
import ErrorMessages from 'Shared/error_messages'
import NoticeMessages from 'Shared/notice_messages'
import ValidationMessages from 'Shared/validation_messages'
import alertTypes from 'Shared/racc_alert_message/constants/alert_message_types'

type alertProps = {
  type: string
  message?: string
  messages?: string[]
}

export type TSetAlert = Dispatch<SetStateAction<alertProps>>

export const EMPTY_ALERT = { type: '', message: '' }

const useAlerts = (): [TSetAlert, () => ReactElement] => {
  const [alert, setAlert] = useState<alertProps>(EMPTY_ALERT)

  const generateAlertMessage = (): JSX.Element => {
    switch (alert.type) {
      case alertTypes.NOTICE:
        return <NoticeMessages messages={[alert.message]} />
      case alertTypes.ERROR:
        return <ErrorMessages messages={[alert.message]} />
      case alertTypes.SUCCESS:
        return <SuccessMessage message={alert.message} />
      case alertTypes.VALIDATION:
        return (
          <ValidationMessages
            messages={alert.messages.map((message) => message)}
          />
        )
      default:
        return null
    }
  }
  return [setAlert, generateAlertMessage]
}

export default useAlerts
